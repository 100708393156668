import React, {useEffect} from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'


function Home() {
  const { t } = useTranslation()
  useEffect(() => {
    navigate(t('translations:urls.root'))
  }, [])
  

  return (
    <></>
  )
}

export default Home